import classNames from 'classnames';
import type { JSX } from 'react';
import IconChevronLeft from '../../../../assets/svg/chevronLeft.svg';
import IconChevronRight from '../../../../assets/svg/chevronRight.svg';
import type { I18nOneDiscoveryFunction } from '../../../../lang';
import I18n from '../../../../lang';
import styles from './NavigationButton.css';

export type NavigationButtonProps = {
  shouldHideOnMobile?: boolean;
  onClick: () => void;
  type: 'previous' | 'next';
  t: I18nOneDiscoveryFunction;
};

function NavigationButton({
  shouldHideOnMobile = false,
  onClick,
  t,
  type,
}: NavigationButtonProps): JSX.Element {
  const title =
    type === 'previous'
      ? t('HorizontalPaging.previous')
      : t('HorizontalPaging.next');
  return (
    <button
      aria-label={title}
      className={classNames(
        styles.NavigationButton,
        styles[`NavigationButton--${type}`],
        {
          [styles['NavigationButton--shouldHideOnMobile']!]: shouldHideOnMobile,
        }
      )}
      onClick={onClick}
      title={title}
      type="button"
    >
      {type === 'previous' ? <IconChevronLeft /> : <IconChevronRight />}
    </button>
  );
}

export { NavigationButton as SimpleNavigationButton };

export default I18n.consumer(NavigationButton);
